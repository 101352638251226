import React from 'react';
import "./Footer.css";
  
const Footer = () => (
  <footer className="footer">
    <div className="logo-footer">
            <img src="./images/logo.png" alt="logo du site" />  
            <div><h1 className='footer-title'>FOOTER</h1></div>            
        </div>    
  </footer>
);
  
export default Footer;