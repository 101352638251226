import React from "react";
import "./Widgets.css";
import SearchIcon from "@mui/icons-material/Search";
import SearchBar from "./SearchBar/SearchBar";
import Tag from "./Tags/Tag";
import PopularMovies from "./PopularMovies/PopularMovies";
import { useGetPopularQuery } from "../../features/Api";

function Widgets() {
  const { data } = useGetPopularQuery();
  const res = data?.results;

  return (
    <>
      
    </>
  );
}

export default Widgets;
