import React from "react";
import "./SlideBar.css";
import HomeMaxIcon from "@mui/icons-material/HomeMax";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import SideItem from "./SideItems/SideItem";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import TvIcon from '@mui/icons-material/Tv';
import MovieCreationIcon from '@mui/icons-material/MovieCreation';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import MovieFilterIcon from '@mui/icons-material/MovieFilter';

function SlideBar() {
  const [show, setShow] = React.useState(false);

  return (
    <>
      <div className={show ? "sidebar side__show" : "sidebar"}>
        <Link to="/">
          {" "}
          <div className="logo">
            <img
              src="./images/logo.png"
              alt="logo"
            />            
          </div>
        </Link>
        <div className="side__box">
          <div className="box__one">
            <span className="heading">Menu</span>
            <SideItem Icon={HomeMaxIcon} Name="Accueil" active link="/" />
            <SideItem Icon={SearchIcon} Name="Recherche" link="/search" />
            <SideItem
              Icon={FavoriteBorderIcon}
              Name="Favoris"
              link="/favorite"
            />
            <SideItem Icon={MovieCreationIcon} Name="Films Tendances" link="/search" />
            <SideItem Icon={SubtitlesIcon} Name="Séries" link="/search" />
            <SideItem Icon={TvIcon} Name="Télé" link="/search" />
            <SideItem Icon={MovieFilterIcon} Name="Filtres" link="/search" />
          </div>
        </div>
        <div className="menu__icon" onClick={() => setShow(!show)}>
          <MenuIcon />
        </div>
      </div>
      <div className={show ? 'layer layer__show' : 'layer'} onClick={() => setShow(false)}></div>
    </>
  );
}

export default SlideBar;
